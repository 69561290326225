@charset "UTF-8";

@font-face {
  font-family: "ui-carousel";
  src:url("fonts/ui-carousel.eot");
  src:url("fonts/ui-carousel.eot?#iefix") format("embedded-opentype"),
    url("fonts/ui-carousel.woff") format("woff"),
    url("fonts/ui-carousel.ttf") format("truetype"),
    url("fonts/ui-carousel.svg#ui-carousel") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "ui-carousel" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="ui-icon-"]:before,
[class*=" ui-icon-"]:before {
  font-family: "ui-carousel" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ui-icon-prev:before {
  content: "\61";
}
.ui-icon-next:before {
  content: "\62";
}
.ui-icon-dot:before {
  content: "\63";
}
